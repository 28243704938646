<template>
  <form @submit="next" style="padding:10px;">
    <label class="labelControl">¿Algún familiar ha tenido cargo público?</label>
    <div class="grid grid-cols-12 gap-4">
      <div class="md:col-start-5 md:col-end-7 col-start-5 col-end-9">
        <input type="button" value="Si" class="buttonWithoutBackground"
          v-bind:style="{backgroundColor: hasHadPublicPosition == true ? activeColorBackground : inactiveColorBackground, color: hasHadPublicPosition == true ? activeColorText : inactiveColorText}" v-on:click="hasHadPublicPosition = true">
      </div>
      <div class="md:col-start-7 md:col-end-9 col-start-5 col-end-9">
        <input type="button" value="No" class="buttonWithoutBackground"
          v-bind:style="{backgroundColor: hasHadPublicPosition == false ? activeColorBackground : inactiveColorBackground, color: hasHadPublicPosition == false ? activeColorText : inactiveColorText}" v-on:click="hasHadPublicPosition = false">
      </div>
      <div class="form-group col-start-5 col-end-9 grid grid-cols-2 gap-4" v-show="hasHadPublicPosition">
        <div class="form-group col-span-2">
          <label class="labelControl">¿Cuál ha sido el puesto desempeñado?</label>
          <select name="select" class="form-control inputControl">
          </select>
        </div>
        <div class="col-span-2">
          <label class="labelControl">¿En que periodo?</label>
        </div>
        <div class="" style="text-align:left;">
          <label class="labelControl">De:</label>
          <input type="text" id="datepickerStartDate" class="form-control inputControl" style="z-index:1"
            minlength="2" maxlength="30" readonly="readonly" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{}">
        </div>
        <div class="" style="text-align:left;">
          <label class="labelControl">A:</label>
          <input type="text" id="datepickerEndDate" class="form-control inputControl" style="z-index:1"
              minlength="2" maxlength="30" readonly="readonly" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{}">
        </div>
      </div>
      <div class="col-start-5 col-end-9">
        <button class="buttonNext">Continuar</button>
      </div>
      <div class="col-start-5 col-end-9">
        <input type="button" value="Atrás" v-on:click="goToBack" class="buttonBack" />
      </div>
    </div>
  </form>
</template>

<script>
import MenuLateral from "@/components/Cuenta/MenuLateral.vue";
import Loading from "@/components/Loading/VueLoading.vue";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      isLoading: false,
      minYearDatePicker: "",
      maxDate: "",
      hasHadPublicPosition: null,
      activeColorText: "#fff",
      inactiveColorText: "#000",
      activeColorBackground: "#223635",
      inactiveColorBackground: "#fff",
    };
  },
  name: "CargoPublicoFamiliar",
  components: {
    MenuLateral,
    Loading,
  },
  props: ['tab','tabBack','step', ,'stepBack'],
  async mounted() {},
  async beforeMount() {
    this.establisDateMaxToday();
    this.establishYearMinDatePicker();
    let self = this;
    $("body").delegate("#datepickerStartDate", "focusin", function () {
      $.datepicker.setDefaults($.datepicker.regional["es"]);
      $("#datepickerStartDate").datepicker({
        changeMonth: true,
        changeYear: true,
        yearRange: self.minYearDatePicker+":+nn",
        dateFormat: 'dd/mm/yy',
        maxDate: new Date(self.maxDate),
        monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        monthNamesShort: ['Ene','Feb','Mar','Abr', 'May','Jun','Jul','Ago','Sep', 'Oct','Nov','Dic'],
        dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        dayNamesShort: ['Dom','Lun','Mar','Mié','Juv','Vie','Sáb'],
        dayNamesMin: ['Do','Lu','Ma','Mi','Ju','Vi','Sá'],
        onSelect:function(selectedDate, datePicker) {
          // self.active_since_datos_laborales = selectedDate;
        }
      });
    });

    $("body").delegate("#datepickerEndDate", "focusin", function () {
      $.datepicker.setDefaults($.datepicker.regional["es"]);
      $("#datepickerEndDate").datepicker({
        changeMonth: true,
        changeYear: true,
        yearRange: self.minYearDatePicker+":+nn",
        dateFormat: 'dd/mm/yy',
        maxDate: new Date(self.maxDate),
        monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        monthNamesShort: ['Ene','Feb','Mar','Abr', 'May','Jun','Jul','Ago','Sep', 'Oct','Nov','Dic'],
        dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        dayNamesShort: ['Dom','Lun','Mar','Mié','Juv','Vie','Sáb'],
        dayNamesMin: ['Do','Lu','Ma','Mi','Ju','Vi','Sá'],
        onSelect:function(selectedDate, datePicker) {
          // self.start_date_public_positions = selectedDate;
        }
      });
    });
  },
  methods: {
    establishYearMinDatePicker() {
      var today = new Date();
      var yyyy = today.getFullYear()-100;

      this.minYearDatePicker = yyyy;
    },
    establisDateMaxToday(){
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth()+1; //January is 0!
      var yyyy = today.getFullYear();
      if(dd<10){
          dd='0'+dd
      } 
      if(mm<10){
          mm='0'+mm
      } 

      today = yyyy+'-'+mm+'-'+dd + 'T10:00:00.000';
      this.maxDate = today;
    },
    next() {
      this.$emit('event-tab', {tab: this.tab, tabBack: this.tabBack, title: '',url:'',step:this.step, stepBack:this.stepBack});
    },
    goToBack() {
      this.$emit('event-tab-back', {tab: this.tab, tabBack: this.tabBack, title: '',url:'',step:this.step, stepBack:this.stepBack});
    }
  },
};
</script>

<style>
.mycontent-left {
  border-right: 1px dashed #333;
  height: 250px;
}
#panel {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

form {
  text-align:center;
}

.form-group {
  text-align: center;
}

.inputControl {
  border: 1px solid #bfd243 !important;
  height: 30px !important;
  text-transform: uppercase;
  border-radius:5px;
}

.labelControl {
  font-size:16px;
  color:#000;
  font-weight:500;
  font-family: 'Roboto';
}

.buttonNext {
  background-color: #bfd243; /* Green */
  opacity: 0.8;
  border: none;
  border-radius: 5px;
  color: white;
  padding: 1px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 13px;
  font-weight: 700;
  font-family:'Roboto';
  widows: 10px;
  width: 100%;
  height: 30px;
  cursor: pointer;
}

.buttonBack {
  background-color: #fff; /* Green */
  border: 1px solid #bfd243;
  border-radius: 5px;
  color: #bfd243;
  padding: 1px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  font-family:Lato;
  widows: 10px;
  width: 100%;
  height: 30px;;
  cursor: pointer;
}

.buttonWithoutBackground {
  background-color: #fff; /* Green */
  opacity: 0.8;
  border: solid 1px;
  border-radius: 5px;
  border-color: #000;
  color: #000;
  padding: 1px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  font-family:'Roboto';
  widows: 10px;
  width: 100%;
  height: 30px;
  cursor: pointer;
}

#datepickerStartDate {
  background-color: #fff;
}

#datepickerEndDate {
  background-color: #fff;
}
</style>